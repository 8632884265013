import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl secretbox`}</strong>{` -- encrypt and authenticate small messages using secret-key cryptography`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl secretbox <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto nacl secretbox`}</strong>{` command group uses secret-key cryptography to
encrypt, decrypt and authenticate messages. The implementation is based on NaCl's
crypto_secretbox function.`}</p>
    <p>{`NaCl crypto_secretbox is designed to meet the standard notions of privacy and
authenticity for a secret-key authenticated-encryption scheme using nonces. For
formal definitions see, e.g., Bellare and Namprempre, "Authenticated encryption:
relations among notions and analysis of the generic composition paradigm,"
Lecture Notes in Computer Science 1976 (2000), 531–545,
`}<a parentName="p" {...{
        "href": "http://www-cse.ucsd.edu/~mihir/papers/oem.html"
      }}>{`http://www-cse.ucsd.edu/~mihir/papers/oem.html`}</a>{`. Note that the length is not
hidden. Note also that it is the caller's responsibility to ensure the
uniqueness of nonces—for example, by using nonce 1 for the first message, nonce
2 for the second message, etc. Nonces are long enough that randomly generated
nonces have negligible risk of collision.`}</p>
    <p>{`By default nonces are alphanumeric, but it's possible to use binary nonces using
the prefix 'base64:' and the standard base64 encoding of the data, e.g.
'base64:081D3pFPBkwx1bURR9HQjiYbAUxigo0Z'. The prefix 'string:' is also
accepted, but it will be equivalent to not using a prefix. Nonces cannot be
longer than 24 bytes.`}</p>
    <p>{`NaCl crypto_secretbox is crypto_secretbox_xsalsa20poly1305, a particular
combination of Salsa20 and Poly1305 specified in "Cryptography in NaCl". This
function is conjectured to meet the standard notions of privacy and
authenticity.`}</p>
    <p>{`These commands are interoperable with NaCl: `}<a parentName="p" {...{
        "href": "https://nacl.cr.yp.to/secretbox.html"
      }}>{`https://nacl.cr.yp.to/secretbox.html`}</a></p>
    <h2>{`Examples`}</h2>
    <p>{`Encrypt a message using a 256-bit secret key, a new nacl box private key can
be used as the secret:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto nacl secretbox seal nonce secretbox.key
Please enter text to seal: 
o2NJTsIJsk0dl4epiBwS1mM4xFED7iE

$ cat message.txt | step crypto nacl secretbox seal nonce secretbox.key
o2NJTsIJsk0dl4epiBwS1mM4xFED7iE
`}</code></pre>
    <p>{`Encrypt the message using a base64 nonce:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat message.txt | step crypto nacl secretbox seal base64:bm9uY2U= secretbox.key
o2NJTsIJsk0dl4epiBwS1mM4xFED7iE
`}</code></pre>
    <p>{`Decrypt and authenticate the message:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo o2NJTsIJsk0dl4epiBwS1mM4xFED7iE | step crypto nacl secretbox open nonce secretbox.key
message
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "open/"
              }}>{`open`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`authenticate and decrypt a box produced by seal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "seal/"
              }}>{`seal`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`produce an encrypted ciphertext`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      